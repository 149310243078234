<template>
    <div class="comments">        
        <div v-if="auth" class="comments-form">
            <textarea v-model="newComment" type="text" placeholder="Comment Here"></textarea>
            <div v-if="errors && errors.body" class="invalid-feedback">
                {{ errors.body[0] }}
            </div>
            <button class="button" @click="addComment">Add comment</button>
        </div>
        
        <comment v-for="comment in comments.data" :key="comment.id" :comment="comment" :post="post"></comment>
        <div class="more-comments">
            <button v-if="comments.next_page_url" @click="fetchComments" class="button">Load More</button>
        </div>
    </div>
</template>

<script>
import Comment from "./comment.vue";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();
export default {
    props: ["post"],
    components: {
        Comment
    },
    mounted() {
        this.fetchComments();
    },
    computed: {
        auth() {
            return __auth();
        }
    },
    data: () => ({
        comments: {
            data: []
        },
        newComment: " ",
        errors: {}
    }),
    methods: {
        fetchComments() {
            const url = this.comments.next_page_url
                ? this.comments.next_page_url
                : `/posts/${this.post.id}/comments`;
            axios.get(url).then(({ data }) => {
                this.comments = {
                    ...data,
                    data: [...this.comments.data, ...data.data]
                };
            });
        },
        addComment() {
            if (!this.newComment) return;
            axios.post(`/comments/${this.post.id}`, {
                    body: this.newComment
                }).then(({ data }) => {
                    this.comments = {
                        ...this.comments,
                        data: [data, ...this.comments.data]
                    };
                    this.newComment = " ";
                    return $toast.success(`${__auth().name}, Your Comment is Posted`, {position: 'top-right'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        console.log(error.response.data);
                    }
                });
        }
    }
};
</script>

<style scoped>

.comments .comments-form {
    padding-top: 40px;
}
.comments .comments-form textarea {
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    width: 100%;
    font-size: 18px;
    height: 35px;
    resize: none;
}

.comments .comments-form textarea::placeholder {
    color: #4e4e4e;
    opacity: 1;
    font-size: 18px;
}

.comments .comments-form textarea::-webkit-input-placeholder {
    color: #4e4e4e;
    font-size: 18px;
}

.comments .comments-form button.button {
    border: 2px solid blueviolet;
    padding: 7px 16px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: blueviolet;
    color: #fff;
    outline: none;
    margin: 10px 0 20px 0;
    font-size: 12px;
    border-radius: 3px;
}

.comments .comments-form button.button:hover {
    border: 2px solid #580c9f;
    background-color: #580c9f;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    font-weight: 600;
}

.comments .comments-form button.button:not(:hover) {
    background-color: blueviolet;
    color: #fff;
    transition: all 0.3s ease-in-out;
    border: 2px solid blueviolet;
    font-weight: 600;
}

.comments .more-comments button.button {
    cursor: pointer;
    border: 2px solid #fff;
    padding: 7px 16px;
    outline: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: blueviolet;
    color: #fff;
    margin: 20px 0;
}

.comments .more-comments button.button:hover {
    background-color: #580c9f;
    color: #fff;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.comments .comments-form .invalid-feedback {
    color: blueviolet;
    text-align: left;
}
</style>
