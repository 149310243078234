<template>
    <vimeo-player :options="{ responsive: true, title: false, byline: false, loop: true, portrait: false }" ref="player" :video-id="videoID" @ready="onReady"></vimeo-player>
</template>
<script>

export default {
    props: ['url'],
    mounted() {
        this.url;
        console.dir(this.url)
    },
    data() {
        return {
            videoID: this.url.toString(),
            options: {},
            playerReady: false
        }
    },
    methods: {
        onReady() {
            this.playerReady = true
        },
        play() {
            this.$refs.player.play()
        },
        pause() {
            this.$refs.player.pause()
        }
    }
}
</script>