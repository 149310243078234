<template>
    <div class="comment">
        <div class="comment-itself">
            <div class="avatar-label">
                <vue-avatar :username="comment.user.name" :size="40"></vue-avatar>
            </div>
            <h6>{{ comment.user.name }}</h6>
            <p>{{ comment.body }}</p>
        </div>
        <div class="replies">
            <div class="comment-likes">
                <!-- Likes Vue Component -->
                <likes :default_likes="comment.likes" :entity_id="comment.id" :entity_owner="comment.user.id"></likes>
            </div>
            <button @click="addingReply = !addingReply" class="add-reply-button">Add reply</button>
            <div v-if="addingReply" class="add-reply">
                <div v-if="auth">
                    <textarea v-model="body" type="text" placeholder="Reply here"></textarea>
                    <div v-if="errors && errors.body" class="invalid-feedback">
                        {{ errors.body[0] }}
                    </div>
                    <button @click="addReply" class="button">Reply</button>
                </div>
                <div v-else>
                    <p class="login-to-answer">Login to reply</p>
                </div>
            </div>
        </div>
        <!-- Replies Vue Component -->
        <replies ref="replies" :comment="comment"></replies>
    </div>
</template>

<script>
import VueAvatar from "@webzlodimir/vue-avatar";
import Replies from "./replies.vue";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import "@webzlodimir/vue-avatar/dist/style.css";

const $toast = useToast();
export default {
    components: {
        Replies,
        VueAvatar
    },
    data() {
        return {
            body: " ",
            addingReply: false,
            errors: {}
        };
    },
    props: {
        comment: {
            required: true,
            default: () => ({})
        },
        post: {
            required: true,
            default: () => ({})
        }
    },
    computed: {
        auth() {
            return __auth();
        }
    },
    methods: {
        addReply() {
            if (!this.body) return;
            axios.post(`/comments/${this.post.id}`, {
                    comment_id: this.comment.id,
                    body: this.body
                }).then(({ data }) => {
                    this.body = " ";
                    this.addingReply = false;
                    this.$refs.replies.addReply(data);
                    return $toast.success(`Your replied to ${this.comment.user.name}'s Comment`, {position: 'top-right'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        console.log(error.response.data);
                    }
                });
        }
    }
};
</script>

<style scoped>
.comment {
    width: 100%;
}
.comment .comment-itself {
    margin: 30px 0;
    display: flow-root;
}

.comment .comment-itself h6 {
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 16px
}

.comment .comment-itself p {
    font-size: 14px;
}

.comment .replies {
    margin-top: 7px;
}

.comment .replies button.add-reply-button {
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    background-color: transparent;
    outline: none;
    color: blueviolet;
    font-size: 13px;
    font-weight: 600;
}

.comment .replies button.add-reply-button:hover {
    color: #580c9f;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.comment .replies .add-reply {
    margin: 40px 0 0 0;
}

.comment .replies .login-to-answer {
    color: blueviolet;
}

.comment .replies .add-reply textarea {
    margin: 0 0 0 65px;
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    width: 100%;
    font-size: 18px;
    height: 35px;
}

.comment .replies .comment-likes {
    margin: 0 10px 0 0;
    float: left;
}

.comment .replies .add-reply button.button {
    border: 2px solid blueviolet;
    padding: 8px 16px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 3px;
    background-color: blueviolet;
    color: #fff;
    margin: 10px 0 20px 65px;
    font-size: 12px;
    font-weight: 600;
}

.comment .replies .add-reply button.button:hover {
    background-color: #580c9f;
    color: #fff;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.comment .comment-itself .avatar-label {
    float: left;
    margin-right: 15px;
}

.comment .replies .invalid-feedback {
    color: blueviolet;
    text-align: left;
    margin-left: 65px;
}
</style>
