import './bootstrap';
import { createApp } from 'vue'
import comments from './components/comments.vue'
import likes from './components/likes.vue'
import treenation from './components/treenation.vue'
import vimeo from './components/vimeo.vue'
import vueVimeoPlayer from 'vue-vimeo-player'


const app = createApp({});
app.use(vueVimeoPlayer)
app.component('comments', comments);
app.component('likes', likes);
app.component('treenation', treenation);
app.component('vimeo', vimeo);
app.mount('#app');

($ = jQuery)("#search").placeholderTypewriter({
  text: ["Sydney Sweeney?", "Kendall Jenner?", "Deva Cassel?", "Celebrity Style?", "Vittoria Ceretti?", "Super Models?", "Shopping?", "Swimsuit?", "Beauty?", "Cosmetics?", "Skin Care?"]
});

jQuery(document).on('copy', function (e) {
  var sel = window.getSelection();
  var copyFooter =
    "<br /><br /> Read more at: <a href='" + document.location.href + "'>" + document.location.href + "</a><br />Copyright © The Fashion Enthusiast";
  var copyHolder = $('<div>', { html: sel + copyFooter, style: { position: 'absolute', left: '-99999px' } });
  $('body').append(copyHolder);
  sel.selectAllChildren(copyHolder[0]);
  window.setTimeout(function () {
    copyHolder.remove();
  }, 0);
});